/* Toolbox category item label */
.blocklyTreeLabel {
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-family: 'Work Sans';
}

/* Toolbox category container */
.blocklyToolboxDiv {
  padding: 0px;
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  min-width: 212px;
}

/* Toolbox category item */
.blocklyToolboxCategory {
  background: #ffffff;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

/* Toolbox category icon */
.icon-arrow {
  display: inline-block;
  vertical-align: middle;
  color: #666;
  box-sizing: border-box;
  position: absolute;
  right: 11px;

  width: 0;
  height: 0;
  border: 3px solid transparent;
  border-top: 3px solid;
  border-right: 3px solid;
  margin-top: 15px;
  transform: rotate(45deg);
}

.icon-arrow:after,
.icon-arrow:before {
  content: '';
  box-sizing: border-box;
}
/* END: Toolbox category icon */

.blocklyTreeRow:hover .icon-arrow {
  color: var(--icon-color);
}

.blocklyToolboxCategory:hover {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.13);
  z-index: 5;
}

.blocklyTreeRow {
  display: flex;
  align-items: center;
  padding: 15px 33px;
  height: 55px;
  margin-bottom: 0px;
  padding-left: 20px !important;
  border-left: 0px !important;
}

.blocklyTreeRowContentContainer > img {
  padding-right: 12px;
}

.blocklyTreeSelected {
  background-color: #ffffff !important;
}
.blocklyTreeSelected .blocklyTreeLabel {
  color: initial !important;
}

/* Dropdown navbar index override */
.blocklyDropDownDiv {
  z-index: 1023;
}
