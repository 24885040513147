.container {
  background: #f9f9f9;
  border-left: 1px solid #d9d9d9;
  max-width: 427px !important;

  .description_or_loader {
    padding-right: 70px;
    .default_title {
      padding: 36px;
      color: #53565a;
    }
    .code_placeholder {
      padding: 0 36px 36px 36px;
    }
  }
}
