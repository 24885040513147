.bg {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: contain;
}

@media (max-width: 991px) {
  .bg {
    background-image: url(Assets/Images/join_bg_lg.png);
  }
}

@media (min-width: 992px) {
  .bg {
    background-image: url(Assets/Images/join_bg_xl.png);
  }
}

.formContainer {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(Assets/Images/join_form_bg_blob_xl.svg);
  aspect-ratio: 0.782;
  max-height: 93vh;
}

.form {
  background: white;
  border-radius: 32px !important;
  /* min-height: 85vh; */
  align-items: center;
  /* width: 100%; */
  padding-top: 70px;
  aspect-ratio: 0.782;
  /*
  margin-top: 4% !important;
  margin-bottom: 2% !important; */
  margin-left: 5% !important;
  margin-right: 4% !important;
  position: relative;
  top: 3%;
  min-height: 550px;
  overflow-y: auto;
}

.form button {
  width: 306px !important;
}

.usernameBlock {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  margin-top: 0.5rem !important;
}
.usernameBlock i {
  color: #6c757d;
  margin: 0 0 0 0.3em;
  vertical-align: -0.1em;
}

.overlay {
  background-color: var(--bs-gray-300);
}
