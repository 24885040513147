.circle {  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  font-size: 1rem;
  margin-right: 0.5rem;
}

// todo replace
.vertical_line {
  height: 30px;
  margin-right: 10px;
}
