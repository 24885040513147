// Font Family
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,400;0,500;0,600;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,300');

/* Bugbox Common */
$bugbox-primary: #ff8000;
$bugbox-text: #171715;
$bugbox-info: #e9f8fe;

/* Bootstrap global theme Customizations */
$primary: $bugbox-primary;
$info: $bugbox-info;

$body-bg: #ffffff;
$body-color: $bugbox-text;

$font-family-sans-serif: 'Work Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;

// navbar
$navbar-brand-font-size: 1rem;
$navbar-light-brand-color: #304559;

@import '~bootstrap/scss/bootstrap';

// todo: custom font
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css');

// Button
.btn {
  --bs-btn-font-weight: 400;
  --bs-btn-font-size: 1rem;
  --bs-btn-color: #{$bugbox-text};
  --bs-btn-border-radius: 2rem;
}

.btn-outline-primary {
  --bs-btn-hover-bg: white !important;
}

$bg-secondary: #fafafa;
$bs-action: #47c7fc;
$action-text-color: #304559;

.btn-action-outline-primary {
  --bs-btn-color: #{$action-text-color};
  --bs-btn-bg: #{$bg-secondary};
  --bs-btn-border-color: #{$bs-action};
  --bs-btn-font-weight: 400;
  --bs-btn-font-size: 1rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-hover-color: #{$action-text-color};
  --bs-btn-hover-bg: #{$white};
  --bs-btn-hover-border-color: #{$bs-action};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: #{$action-text-color};
  --bs-btn-active-bg: #{shade-color($white, 20%)};
  --bs-btn-active-border-color: #{$bs-action};
}
.btn-action-outline-primary .bi {
  padding-right: 10px;
  color: #{$bs-action};
}

.btn-action-outline-secondary {
  --bs-btn-color: #{$bugbox-primary};
  --bs-btn-bg: #{$bg-secondary};
  --bs-btn-border-color: #{$bugbox-primary};
  --bs-btn-font-weight: 400;
  --bs-btn-font-size: 1rem;
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-hover-color: #{$bugbox-primary};
  --bs-btn-hover-bg: #{$white};
  --bs-btn-hover-border-color: #{$bugbox-primary};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: #{$bugbox-primary};
  --bs-btn-active-bg: #{shade-color($white, 20%)};
  --bs-btn-active-border-color: #{$bugbox-primary};
}
.btn-action-outline-secondary .bi {
  padding-right: 10px;
  color: #{$bugbox-primary};
}

.btn-outline-secondary {
  // --bs-btn-color: #{$bugbox-primary};
  // --bs-btn-bg: #{$bg-secondary};
  // --bs-btn-border-color: #{$bugbox-primary};
  // --bs-btn-font-weight: 400;
  // --bs-btn-font-size: 1rem;
  // --bs-btn-padding-x: 0.75rem;
  /// --bs-btn-padding-y: 0.375rem;
  --bs-btn-hover-color: #{$bugbox-primary};
  --bs-btn-hover-bg: #{$white};
  --bs-btn-hover-border-color: #{$bugbox-primary};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: #{$bugbox-primary};
  --bs-btn-active-bg: #{shade-color($white, 20%)};
  --bs-btn-active-border-color: #{$bugbox-primary};
}

.alert-info {
  background-color: $bugbox-info;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.text-xl {
  font-size: $font-size-base * 1.125;
  font-weight: 400;
}

.w80 {
  width: 900px;
  max-width: none !important;
}

/**
 * Codemirror editor styles
 */
.cm-editor {
  height: calc(100vh - (60px + 76px + 10px));
}
// END: Codemirror editor styles

/**
 * Blockly variable modal styles
 */
.blockly-var-backdrop-bg {
  background: #000000bf;
}

.blockly-var-modal {
  display: block;
  width: 400px;
  height: auto;
  position: initial;
  margin: 20px auto 0px;
}
// END: Blockly variable modal styles

@media (max-width: 991.98px) { 

  .nav-item {
    padding-bottom: 10px !important;
}

  .navbar-nav {
    align-items: flex-end !important;
  }
 }